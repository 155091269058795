import { db } from '@/firebase/config'

const getSeniorCompany = async (store, companyId) => {
	try {
		await db.collection("Companies").doc(companyId).get().then(doc => {
			store.value.push({...doc.data(), id: doc.id})
		})
	} catch (error) {
		alert("getSeniorCompany:" + error);
	}
}

export default getSeniorCompany