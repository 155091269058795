<template>
  <div v-if="isLoaded" class="wish-container">
    <MyCompany :myCompany="myCompany" />
    <hr class="good-line" />
    <AllCompanies />
  </div>
</template>

<script>
import { ref, watchEffect, onMounted } from "vue";
import getUser from "@/composables/getUser";
import getProfile from "@/composables/Profile/getProfile";
import getSeniorCompany from "@/composables/WishList/getSeniorCompany";
import MyCompany from "@/components/WishList/myCompany.vue";
import PopularCompanies from "@/components/WishList/popularCompanies.vue";
import AllCompanies from "@/components/WishList/allCompanies.vue";

export default {
  components: {
    MyCompany,
    PopularCompanies,
    AllCompanies,
  },
  async setup() {
    const { user } = getUser();
    const profile = ref(null);
    const myCompany = ref([]);
    const isLoaded = ref(false);

    onMounted(async () => {
      await getProfile(profile, user.value.uid);
      await getSeniorCompany(myCompany, profile.value.company);
      isLoaded.value = true;
    });

    return {
      profile,
      myCompany,
      isLoaded,
    }
  }
}
</script>

<style scoped>
.wish-container{
  width: 90%;
  margin: 0 auto 50vh auto;
}

</style>