<template>
  <div class="myCompany-container">
    <h2>My Current Company</h2>
    <h4 v-if="company[0].id=='default'||company[0].id==null">
      You haven't told us your company yet.
      <br/>
      (Please choose your company with the edit button on Home page.)
    </h4>
    <CompanyList v-if="company[0].id!='default'&&company[0].id!=null" :companies="company"/>
  </div>
</template>

<script>
import {ref} from "vue";
import CompanyList from "@/components/WishList/companyList.vue";

export default {
  props: ["myCompany"],
  components: {
    CompanyList,
  },
  async setup(props) {
    const company = ref(props.myCompany);

    return {
      company,
    }
  }
}
</script>

<style scoped>
.myCompany-container {
  width: 100%;
  margin-top: 5vh;
  animation: fadein 1s;
}

.myCompany-container h2 {
  padding-left: 2vw;
  font-weight: 400;
}

.myCompany-container h4 {
  height: 20vh;
  padding: 6vh 2vw;
  font-weight: 400;
}
</style>
